import { Observable } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { AuthRepository } from '../../../domain/repositories/auth.repository';
import { UseCase } from '@domain/base/use-case';
import { AuthModel } from '@domain/models/login-model';
import { AuthenticationModel } from '@domain/models/auth.model';


@Injectable({
  providedIn: 'root',
})
export class LoginUseCase implements UseCase<AuthModel, AuthenticationModel> {
  constructor(private authRepository:AuthRepository ) { }
  execute(authModel: AuthModel): Observable<AuthenticationModel> {
    return this.authRepository.login(authModel);
  }
}

import { Observable } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { AuthRepository } from '../../../domain/repositories/auth.repository';
import { UseCase } from '@domain/base/use-case';
import { AuthModel } from '@domain/models/login-model';



@Injectable({
  providedIn: 'root',
})
export class RecoveryUseCase implements UseCase<AuthModel, boolean> {
  constructor(private authRepository:AuthRepository ) { }
  execute(authModel: AuthModel): Observable<boolean> {
    return this.authRepository.recovery(authModel);
  }
}
